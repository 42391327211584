import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchLCL(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/prj/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchAgisci(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/prj/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProjects(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/prj/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTimesheet(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/workhours/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTasks(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/prj/lines', { params: payload })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUpdateTask(ctx, { task, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsprj/task/addupdate', { task, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeTask(ctx, { task, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/wsprj/task/delete`, { task, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmployees(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/base/ohem/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProject(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsprj/project/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSubProjectsList(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsprj/subprojects/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProjectTask(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsprj/task/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUpdateProject(ctx, { projectData, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsprj/project/addupdate', { prj: projectData, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBPs(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/bp/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchBP(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsprj/bp/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleEvent(ctx, { clgcode }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsprj/event/${clgcode}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchActivityCalendar(ctx, { info, calendars, user }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/activity/list', { params: { info, calendars, user } })
          .then(response => {
            resolve(response.data.events)
          })
          .catch(error => reject(error))
      })
    },
    fetchActivityTypes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/activity/types', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUpdateActivityType(ctx, { obj, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsprj/activitytype/addupdate', { obj, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUpdateCostCenter(ctx, { obj, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsprj/costcenter/addupdate', { obj, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchItemData(ctx, { ItemCode }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsprj/items', ItemCode)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCostCenter(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/costcenter/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEmpList(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/wsprj/empgroup/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchmpListDetails(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsprj/empgroup/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addUpdateWorkHours(ctx, { workhours, userData }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsprj/workhours/addupdate', { obj: workhours, user: userData })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSingleTimesheet(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/wsprj/timesheet/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    ApproveRejectWorkHours(ctx, {
      workhours_id, userData, action, reason,
    }) {
      return new Promise((resolve, reject) => {
        axios
          .post('/wsprj/workhours/approvation', {
            ids: workhours_id, user: userData, action, reason,
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
