import { ref, watch, computed } from '@vue/composition-api'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useProjectsList() {
  // Use toast
  const toast = useToast()

  const refProjectListTable = ref(null)
  const refTaskListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'STATUS', sortable: true },
    { key: 'DocNum', label: '#', sortable: true },
    { key: 'FIPROJECT', label: 'Project', sortable: true },
    { key: 'NAME', label: 'Description', sortable: true },
    { key: 'CardCode', label: 'Business Partner', sortable: true },
    { key: 'START', label: 'Start Date', sortable: true },
    { key: 'CLOSING', label: 'End Date', sortable: true },
    { key: 'EMPLOYEE', label: 'Owner', sortable: true },
    // { key: 'REASON', label: 'Remarks' },
    { key: 'actions' },
  ]

  const cflProjectsTableColumns = [
    { key: 'STATUS', sortable: true },
    { key: 'FIPROJECT', label: 'Project', sortable: true },
    { key: 'NAME', label: 'Description', sortable: true },
    { key: 'CardCode', label: 'Business Partner', sortable: true },
    { key: 'START', label: 'Start Date', sortable: true },
    { key: 'CLOSING', label: 'End Date', sortable: true },
  ]

  const taskTableColumns = [{ key: 'UniqueID', label: "Unique ID", sortable: true }, { key: 'DSCRIPTION', label: "Description", sortable: true }, { key: 'START', label: "Start Date", sortable: true }, { key: 'CLOSE', label: "End Date", sortable: true }, { key: 'FINISH', label: "Completed", sortable: true }]
  const perPage = ref(10)
  const totalProjects = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('FIPROJECT')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  const ProjectAbsEntryFilter = ref(null)
  const empID = ref(null)

  const perPageG = ref(10)
  const totalTasks = ref(0)
  const currentPageG = ref(1)
  const perPageOptionsG = [10, 25, 50, 100]
  const searchQueryG = ref('')
  const sortByG = ref('LineID')
  const isSortDirDescG = ref(false)

  const dataMeta = computed(() => {
    const localProjectsCount = refProjectListTable.value ? refProjectListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localProjectsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localProjectsCount,
      of: totalProjects.value,
    }
  })

  const dataMetaG = computed(() => {
    const localTasksCount = refTaskListTable.value ? refTaskListTable.value.localItems.length : 0
    return {
      from: perPageG.value * (currentPageG.value - 1) + (localTasksCount ? 1 : 0),
      to: perPageG.value * (currentPageG.value - 1) + localTasksCount,
      of: totalTasks.value,
    }
  })

  const refetchData = () => {
    console.log(refProjectListTable.value)
    refProjectListTable.value.refresh()
  }
  const refetchDataG = () => {
    console.log(refTaskListTable.value)
    refTaskListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter, empID], () => {
    refetchData()
  })
  watch([currentPageG, perPageG, searchQueryG, ProjectAbsEntryFilter], () => {
    refetchDataG()
  })
  const fetchTasks = (ctx, callback) => {
    const queryParams = {
      q: searchQueryG.value,
      perPage: perPageG.value,
      page: currentPageG.value,
      sortBy: sortByG.value,
      sortDesc: isSortDirDescG.value,
      AbsEntry: ProjectAbsEntryFilter.value,
    }
    store
      .dispatch('app-prj/fetchTasks', queryParams)
      .then(response => {
        const { projectLines, total } = response.data

        callback(projectLines)
        totalTasks.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching tasks list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  const fetchProjects = (ctx, callback) => {
    let status = null
    if (statusFilter.value) {
      status = statusFilter.value.value
    }
    let empid = null
    if (empID.value) {
      if (empID.value.value) { empid = empID.value.value } else { empid = empID.value }
    }
    store
      .dispatch('app-prj/fetchProjects', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status,
        EmpID: empid,
      })
      .then(response => {
        const { projects, total } = response.data

        callback(projects)
        totalProjects.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Projects' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchLCL = (ctx, callback) => {
    let status = null
    if (statusFilter.value) {
      status = statusFilter.value.value
    }
    let empid = null
    if (empID.value) {
      if (empID.value.value) { empid = empID.value.value } else { empid = empID.value }
    }
    store
      .dispatch('app-prj/fetchProjects', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status,
        EmpID: empid,
      })
      .then(response => {
        const { projects, total } = response.data

        callback(projects)
        totalProjects.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Projects' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchAgisci = (ctx, callback) => {
    let status = null
    if (statusFilter.value) {
      status = statusFilter.value.value
    }
    let empid = null
    if (empID.value) {
      if (empID.value.value) { empid = empID.value.value } else { empid = empID.value }
    }
    store
      .dispatch('app-prj/fetchProjects', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status,
        EmpID: empid,
      })
      .then(response => {
        const { projects, total } = response.data

        callback(projects)
        totalProjects.value = total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching Projects' list",
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveProjectStatusVariantAndIcon = (status, duedate) => {
    // if (status === 'S') return { variant: 'success', icon: 'PlayCircleIcon' } // avviato
    if (status === 'P') return { variant: 'warning', icon: 'PauseCircleIcon' } // sospeso
    if (status === 'T') return { variant: 'danger', icon: 'XCircleIcon' } // interrotto
    if (status === 'F') return { variant: 'primary', icon: 'StopCircleIcon' } // terminato
    if (status === 'C') return { variant: 'primary', icon: 'StopCircleIcon' } // terminato
    if (status === 'D') return { variant: 'secondary', icon: 'SaveIcon' }
    if (status === 'S' && Date.parse(duedate) > new Date()) return { variant: 'danger', icon: 'PlayCircleIcon' }
    if (status === 'S' && Date.parse(duedate) <= new Date()) return { variant: 'success', icon: 'PlayCircleIcon' }
    if (status === 'O' && Date.parse(duedate) > new Date()) return { variant: 'danger', icon: 'PlayCircleIcon' }
    if (status === 'O' && Date.parse(duedate) <= new Date()) return { variant: 'success', icon: 'PlayCircleIcon' }
    if (status === 'S') return { variant: 'success', icon: 'PlayCircleIcon' } // avviato
    if (status === 'O') return { variant: 'success', icon: 'PlayCircleIcon' } // avviato
    return { variant: 'secondary', icon: 'XIcon' }
  }

  const resolveClientAvatarVariant = (status, duedate) => {
    if (status === 'Partial Payment') return 'primary'
    if (status === 'Paid') return 'danger'
    if (status === 'Downloaded') return 'secondary'
    if (status === 'Draft') return 'warning'
    if (status === 'Sent') return 'info'
    if (status === 'Past Due') return 'success'
    return 'primary'
  }

  return {
    fetchProjects,
    tableColumns,
    perPage,
    currentPage,
    totalProjects,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refProjectListTable,
    empID,
    statusFilter,
    ProjectAbsEntryFilter,
    resolveProjectStatusVariantAndIcon,
    resolveClientAvatarVariant,

    refetchData,

    taskTableColumns,
    cflProjectsTableColumns,
    fetchTasks,
    refTaskListTable,
    perPageG,
    currentPageG,
    totalTasks,
    dataMetaG,
    perPageOptionsG,
    searchQueryG,
    sortByG,
    isSortDirDescG,
    refetchDataG,

    fetchLCL,
    fetchAgisci,
  }
}
