<template>
  <section id="gestione-squadre">
    <b-row>
      <b-col md="12">
        <b-card-actions
          ref="refreshCard"
          title="Gestione Squadra"
          action-refresh
          @refresh="refreshStop('refreshCard')"
        >
          <b-card-text>
            <vue-autosuggest
              ref="autocomplete"
              v-model="query"
              :suggestions="suggestions"
              :input-props="inputProps"
              :section-configs="sectionConfigs"
              :render-suggestion="renderSuggestion"
              :get-suggestion-value="getSuggestionValue"
              @input="fetchResults"
            />
          </b-card-text>
          <b-card-text>
            <vue-autosuggest
              ref="autocomplete"
              v-model="searchQuery"
              :suggestions="suggestions"
              :input-props="inputProps"
              :section-configs="sectionConfigs"
              :render-suggestion="renderSuggestion"
              :get-suggestion-value="getSuggestionValue"
              @input="fetchProjectsResults"
            />
          </b-card-text>
          <b-card-text class="mt-1">
            Selected element (object):
          </b-card-text>
          <b-card-text
            class="border"
            no-body
          >
            <pre>{{ JSON.stringify(selected, null, 4) }}</pre>
          </b-card-text>

        </b-card-actions>
      </b-col>
    </b-row>
  </section>
</template>

<script>
/* eslint-disable vue/no-unused-components */
/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */

import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow, BCol, BTable, BCardText, BAvatar,
} from 'bootstrap-vue'
import { VueAutosuggest } from 'vue-autosuggest'
// eslint-disable-next-line import/no-cycle
import { avatarText } from '@core/utils/filter'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { getUserData } from '@/auth/utils'
import store from '@/store'
import axios from 'axios'
import useProjectList from './useProjectList'
import projectsStoreModule from './projectsStoreModule'

export default {
  components: {
    BCardActions,
    BRow,
    BCol,
    BTable,
    BCardText,
    BAvatar,
    VueAutosuggest,
  },
  setup() {
    const employees = ref([])
    const PROJECT_APP_STORE_MODULE_NAME = 'app-prj'

    // Register module
    if (!store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.registerModule(PROJECT_APP_STORE_MODULE_NAME, projectsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PROJECT_APP_STORE_MODULE_NAME)) store.unregisterModule(PROJECT_APP_STORE_MODULE_NAME)
    })
    const suggestions = ref([])
    const empOptions = ref([])
    const query = ref("")
    const userData = getUserData()
    console.log(userData)
    const {
      fetchLCL,
      fetchAgisci,
      searchQuery,
    } = useProjectList()

    store.dispatch('app-prj/fetchEmployees', { })
      .then(res => {
        console.log(res.data)
        employees.value = res.data.ohem
        const emps = employees.value.map(x => ({ label: `${x.lastName} ${x.firstName}`, value: x.empID }))

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < emps.length; i++) {
          empOptions.value.push(emps[i])
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          employees.value = undefined
        }
      })

    const filterResults = (data, text, field) => data.filter(item => {
      if (item[field].toLowerCase().indexOf(text.toLowerCase()) > -1) {
        return item[field]
      }
      return false
    }).sort()

    const timeout = ref(null)
    const debounceMilliseconds = 250
    const fetchProjectsResults = () => {
      // const { query } = this
      console.log(query.value)
      clearTimeout(timeout.value)
      timeout.value = setTimeout(() => {
        const lclPromise = fetchLCL()
        const agisciPromise = fetchAgisci()

        Promise.all([lclPromise, agisciPromise]).then(values => {
          console.log(values)
          suggestions.value = []
          this.selected = null

          const lcl = this.filterResults(values[0].data, query.value, 'title')
          const agisci = this.filterResults(values[1].data, query.value, 'name')
          lcl.length && suggestions.value.push({ name: 'lcl', data: lcl })
          agisci.length && suggestions.value.push({ name: 'agisci', data: agisci })
        })
      }, debounceMilliseconds)
    }

    return {
      fetchLCL,
      fetchAgisci,
      fetchProjectsResults,
      filterResults,

      suggestions,
      avatarText,

      employees,
      empOptions,
      query,
      searchQuery,
    }
  },
  data() {
    return {
      results: [],
      timeout: null,
      selected: null,
      debounceMilliseconds: 250,
      usersUrl: 'https://jsonplaceholder.typicode.com/users',
      photosUrl: 'https://jsonplaceholder.typicode.com/photos',
      inputProps: {
        id: 'autosuggest__input_ajax',
        placeholder: 'Seleziona codice LCL o Agisci',
        class: 'form-control',
        name: 'ajax',
      },
      sectionConfigs: {
        destinations: {
          limit: 6,
          label: 'Destination',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
        hotels: {
          limit: 6,
          label: 'Hotels',
          onSelected: selected => {
            this.selected = selected.item
          },
        },
      },
      limit: 10,
      fields: [
        'ACTION',
        { key: 'ICON', label: 'ICON' },
        'DETAILS',
      ],
      items: [
        { ACTION: 'Collapse', ICON: 'ChevronDownIcon', DETAILS: 'Collapse card content using collapse action.' },
        { ACTION: 'Refresh Content', ICON: 'RotateCwIcon', DETAILS: 'Refresh your card content using refresh action.' },
        { ACTION: 'Remove Card', ICON: 'XIcon', DETAILS: 'Remove card from page using remove card action' },
      ],
    }
  },
  created() {
    this.$http.get('/autosuggest/data')
      .then(res => { this.datasuggest = res })
  },
  methods: {

    // stop refreshing card in 3 sec
    refreshStop(cardName) {
      setTimeout(() => {
        this.$refs[cardName].showLoading = false
      }, 3000)
    },

    fetchResults() {
      const { query } = this
      console.log(query)
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        const photosPromise = axios.get(this.photosUrl)
        const usersPromise = axios.get(this.usersUrl)

        Promise.all([photosPromise, usersPromise]).then(values => {
          this.suggestions = []
          this.selected = null

          const photos = this.filterResults(values[0].data, query, 'title')
          const users = this.filterResults(values[1].data, query, 'name')
          users.length && this.suggestions.push({ name: 'destinations', data: users })
          photos.length && this.suggestions.push({ name: 'hotels', data: photos })
        })
      }, this.debounceMilliseconds)
    },
    renderSuggestion(suggestion) {
      if (suggestion.name === 'hotels') {
        const image = suggestion.item
        return (
          <div class="d-flex">
            <div>
              <b-avatar src={image.thumbnailUrl} class="mr-50"></b-avatar>
            </div>
            <div>
              <span>{image.title}</span>
            </div>
          </div>
        )
      }
      return suggestion.item.name
    },
    getSuggestionValue(suggestion) {
      const { name, item } = suggestion
      return name === 'hotels' ? item.title : item.name
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss" scoped>
pre{
  min-height: 295px;
  padding: 1.5rem;
  margin-bottom: 0;
  border-radius: .5rem;
}
</style>
